@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Cookie);
@import url(https://fonts.googleapis.com/css?family=Cabin);
@import url(https://fonts.googleapis.com/css?family=Tangerine);

$headings-font-family: 'Tangerine', cursive;
$font-size-h1: 44px;
$font-size-h2: 38px;
$font-size-h3: 28px;
$font-size-h4: 24px;
$font-size-h5: 24px;

$font-family-base: 'Cabin', cursive;
$font-size-base: 16px;

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
